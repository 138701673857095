export const menusHeader = [
  {
    id: '6',
    title: 'Trang chủ',
    pathname: '/',
  },
  {
    id: '0',
    title: 'Insights',
    pathname: '/insight',
  },
  {
    id: '1',
    title: 'Kiến thức',
    pathname: '/video-kien-thuc',
  },
  {
    id: '2',
    title: 'Đánh giá sàn',
    pathname: '/danh-gia-san',
  },
  {
    id: '3',
    title: 'Tin tức',
    pathname: '/tin-tuc',
  },
  // {
  //   id: '4',
  //   title: 'Blog',
  //   pathname: '/blog',
  // },
  {
    id: '5',
    title: 'Về chúng tôi',
    pathname: '/ve-chung-toi',
  },
];
